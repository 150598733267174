.nav-link-light {
  display: flex !important;
  padding: 0.5rem 1rem !important;
  font-size: 1.5rem !important;
  font-weight: 500 !important;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out !important;
  flex-direction: row !important;
  justify-content: space-between !important;
  align-items: center !important;
  text-decoration: none !important;
  margin-bottom: 0.5rem !important;
  transition: color 0.2s !important;
  margin: 1rem 0.5rem !important;
  border-bottom: 1px solid #59bdb3;
  padding: 1.4rem !important;
  border-radius: 0 !important;
  background-color: transparent !important;

  &:hover {
    text-decoration: none !important;
    background-color: #59bdb3 !important;
    color: #ffffff !important;
    border-radius: 8px !important;
  }
}
.nav-link {
  display: flex;
  padding: 0.5rem 1rem;
  font-size: 1.2rem !important;
  font-weight: 500;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}
.nav-light {
  background-color: transparent;
  padding: 1rem;
  border-radius: 4px;
  margin: 0.5rem 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.nav-icon {
  margin-right: 0.5rem;
}
// .nav-link-dark {
//   margin: 0 0.5rem;
//   color: #fff;
//   font-weight: bold;
//   text-decoration: none;
//   margin-bottom: 0.5rem;
//   transition: color 0.2s;
// }

.nav-link-dark:hover {
  text-decoration: none;
}
