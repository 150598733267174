/* Sidebar Container */
.sidebar {
  background-color: #001529; /* Sidebar background color */
  overflow: auto;
  height: 100vh;
  position: fixed;
  left: 0;
  transition: width 0.3s;
}

/* Sidebar Logo */
.logo {
  text-align: center;
  padding: 20px 0;
}

/* User Profile Section */
.user-profile {
  text-align: center;
  padding: 20px 0;
}

/* Username */
.username {
  display: block;
  color: #fff;
  margin-top: 10px;
  font-size: 16px;
  postion: absolute;
  bottom: 2rem;
}

/* Avatar */
.user-profile .ant-avatar {
  position: absolute;
  bottom: 2rem;
  left: 5rem;
  color: #fff;
  background-color: #1890ff; /* Avatar background color */
}

/* Avatar Image */
.user-profile .ant-avatar img {
  max-width: 100%;
  max-height: 100%;
}

/* Define a class for the footer */
.footer {
  background-color: #f8f8f8; /* Background color for the footer */
  padding: 20px; /* Adjust padding as needed */
  text-align: center;
  position: fixed;
  bottom: 0; /* Stick to the bottom of the viewport */
  width: 100%; /* Full width */
  z-index: 100; /* Adjust the z-index to ensure it's above other content */
}
