@import "@webpixels/css/core/functions";

// Your variable overrides
$primary: #59bdb3;
$body-bg: #292727;
$blue: #001a4d;
$indigo: #2d2d4d;
$purple: #4c1130;
$pink: #b30047;
$red: #801515;
$orange: #b34d4d;
$yellow: #b39c00;
$green: #005c4d;
$teal: #007a86;
$cyan: #0088cc;
$secondary: #eae8e8;
$bg-dark: #17192c;
$bg-secondary: #eae8e8;
* {
  scrollbar-width: bold;
}
/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 4px;
  background-color: #ffffff;
  color: $secondary;
}
*::-webkit-scrollbar-track {
  background-color: #ffffff;
  color: $secondary;
  width: 6px;
  border-radius: 4px;
}
*::-webkit-scrollbar-thumb {
  background-color: $secondary;
  color: $secondary;
  border-radius: 6px;
}
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.7.2/font/bootstrap-icons.css");

@import "@webpixels/css/base";
@import "@webpixels/css/forms";
@import "@webpixels/css/components";
@import "@webpixels/css/utilities";
// Import Bootstrap's source SCSS

// Stripe-like dark mode colors
$stripe-dark-bg: $primary;
$stripe-dark-text: #c9c6c6;
$stripe-dark-primary: $primary;
$stripe-dark-secondary: $secondary;

// Override Bootstrap default colors
$theme-colors: (
  "primary": $stripe-dark-primary,
  "secondary": $stripe-dark-secondary,
  "dark": $stripe-dark-bg,
  "light": $stripe-dark-text,
);

body {
  background-color: #ffffff !important;
  color: #000000 !important;
}

a {
  color: $teal;
  text-decoration: none;
  &:hover {
    color: $cyan;
    text-decoration: underline;
  }
}

a:hover {
  color: darken($stripe-dark-primary, 10%);
}

.btn-primary {
  background-color: $stripe-dark-primary;
  border-color: $stripe-dark-primary;
}

.authContainer {
  background-image: linear-gradient(
    160deg,
    rgb(255, 255, 255) 0%,
    rgb(255, 255, 255) 24%,
    rgb(255, 255, 255) 49%,
    rgb(128, 208, 199) 75%,
    rgb(0, 147, 233) 100%
  );
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active {
  background-color: darken($stripe-dark-primary, 5%);
  border-color: darken($stripe-dark-primary, 5%);
}

.btn-role {
  background-color: transparent !important;
  padding: 0 !important;
  border: 0 !important;
  // hover
  &:hover {
    background-color: transparent !important;
    padding: 0 !important;
    border: 0 !important;
  }
  // focus
  &:focus {
    background-color: transparent !important;
    padding: 0 !important;
  }
}

.form-check-input:checked {
  background-color: $stripe-dark-primary;
  border-color: $stripe-dark-primary;
}
@import "custom_elements.scss";

.w-100 {
  width: 100% !important;
}
/* StepTabs.css */
.step-tabs {
  display: flex;
  justify-content: space-between;
  list-style-type: none;
  padding: 0;
}

.step-tabs li {
  position: relative;
  flex: 1;
  text-align: center;
}

.step-tabs li:not(:last-child)::after {
  content: "";
  position: absolute;
  top: 50%;
  right: -1px;
  width: 20px;
  height: 2px;
  background-color: #aaa;
}

.step-tab {
  cursor: pointer;
  padding: 10px 20px;
  border: 1px solid #ccc;
  border-bottom: none;
  background-color: #f1f1f1;
  text-align: center;
  transition: background-color 0.2s ease;
}

.step-tab:hover {
  background-color: #ddd;
}

.step-tab.active {
  background-color: #fff;
  cursor: default;
}

aside {
  .accordion {
    box-shadow: 1px 2px 12px #ccc;
  }
  .btn-sm {
    padding: 0.2rem 0.4rem;
    font-size: 0.875rem;
  }
}

.form-control {
  background-color: darken($stripe-dark-bg, 5%);
  color: $bg-dark;
  border-color: rgb(176 177 189 / 50%);
}

.form-control:focus {
  border-color: $stripe-dark-primary;
  box-shadow: 0 0 0 0.2rem rgba($stripe-dark-primary, 0.25);
}
.btn-circle.btn-sm {
  width: 30px;
  height: 30px;
  padding: 6px 0px;
  border-radius: 15px;
  font-size: 8px;
  text-align: center;
}
.btn-circle.btn-md {
  width: 50px;
  height: 50px;
  padding: 7px 10px;
  border-radius: 25px;
  font-size: 10px;
  text-align: center;
}
.btn-circle.btn-xl {
  width: 70px;
  height: 70px;
  padding: 10px 16px;
  border-radius: 35px;
  font-size: 12px;
  text-align: center;
}
.creatorControl {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  padding: 5px 0px 8px 0px;
  position: fixed !important;
  width: 100vw;
  background-color: $primary;
  top: -8px;
  left: 0;
  height: 58px;
  z-index: 1000;
  box-shadow: 2px 0 3px #7c7979;
  .creator-control {
    padding: 5px 13px 8px 0px;
  }
  .creator-input {
    flex: 1 1;
    margin-right: 0.4rem;
    background-color: transparent;
    border: 0;
    color: #f9f9f9;
    border-bottom: 1px $secondary solid;
    border-radius: 0;
    width: 20rem;
  }

  .creator-input:focus {
    background-color: $cyan;
    background-color: #b4c0e5;
    color: #f3f2f2;
  }

  @media (max-width: 576px) {
    .creator-input {
      max-width: 9rem;
      font-size: 1rem;
    }
  }

  @media (min-width: 992px) {
    i {
      width: 32px;
    }

    a {
      margin: 4px;
      padding: 8px;
      font-size: 1rem;
    }
  }
}

.creatorControlbtm {
  padding: 10px;
  background-color: #f0f0f06b;
  box-shadow: 0px 1px 4px #ccc;
  position: relative;
  // height: 100%;

  i {
    width: 30px;
  }
  a {
    border: 0.6px solid #a0a0a059;
    padding: 5px;
    margin: 3px;
    box-shadow: 0 0.4px 0 #ccc;
    border-radius: 5px;
    background-color: white;
    color: black;
    float: right;
    &:hover {
      background-color: $primary;
      color: white;
    }
  }
}
.pdf-preview {
  width: 850px;
  height: 850px;
  object-fit: contain;
  // small laptop view
  @media (max-width: 1500px) {
    width: 770px;
    height: 770px;
  }
  @media (max-width: 576px) {
    width: 400px;
    height: 500px;
  }
}
.navbar-bottom {
  background-color: #f8f9fa;

  a {
    color: #0d6efd;
  }

  .bi {
    font-size: 1.5rem;
  }
}
.editorside {
  overflow-x: hidden !important;
  max-height: 100vh;
  box-shadow: 0px 1px 5px #8b8787;
  z-index: 1;
}
.editorcard {
  border: 1px #dddddd solid;
  border-radius: 8px !important;
  background-color: white !important;
  padding: 1rem !important;

  .card-header {
    color: black;
    font-weight: 500;
    font-size: 1.2rem;
    padding: 9px !important;
  }
  .deleteItem {
    button {
      position: absolute;
      top: 17px;
      right: 1px;
      font-size: 1.5rem;
    }
  }
  label {
    color: #000000;
  }
}
.accordion-button:not(.collapsed)::after {
  background-image: none !important;
  content: "\F149";

  font-family: bootstrap-icons !important;
}
.accordion-button::after {
  background-image: none !important;
  content: "\F14A";

  font-family: bootstrap-icons !important;
}
.accordion-button:focus {
  border: 0 !important;

  box-shadow: 0 0 0 0 rgb(80 193 224 / 25%) !important;
}

.explainer {
  background-color: #fff;
  padding: 5px;
  box-shadow: 0 2px 2px #ccc;
}

.accordion-header {
  h4 {
    width: 71%;
  }
  .btn {
    z-index: 90000;
    border-radius: 2px !important;
    border: $primary solid !important;
  }
}

.card-body {
  .deleteItem {
    position: absolute;
    right: 3px;
    top: 0px;
  }
}

.job-main {
  // background-image: url("./static/jobs_blurred.png");
  background-position: 0 0;
  height: 84vh;
  background-size: 100% 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h1 {
    padding: 12px;
    background-color: $primary;
    color: #fff;
  }
}
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.gradient {
  --size: 450px;
  --speed: 50s;
  --easing: cubic-bezier(0.8, 0.2, 0.2, 0.8);
  z-index: -10;
  top: 0;
  position: fixed;
  width: var(--size);
  height: var(--size);
  filter: blur(calc(var(--size) / 5));
  background-image: linear-gradient(hsl(158, 82, 57, 85%), hsl(252, 82, 57));
  animation: rotate var(--speed) var(--easing) alternate infinite;
  border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
}

@media (min-width: 720px) {
  .gradient {
    --size: 500px;
  }
}
