@import "bootstrap/scss/bootstrap";

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Source Sans Pro", sans-serif;
}

// Typography
$h1-font-size: 2.5rem;
$h2-font-size: 2rem;
$h3-font-size: 1.75rem;
$h4-font-size: 1.5rem;
$h5-font-size: 1.25rem;
$h6-font-size: 1rem;

$border-radius: 1px;

// Override Bootstrap default colors
$body-bg: $stripe-dark-bg;
$body-color: $stripe-dark-text;
$primary: $stripe-dark-primary;
$secondary: $stripe-dark-secondary;

// Checkboxes
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: $stripe-dark-primary;
  border-color: $stripe-dark-primary;
}

.custom-checkbox
  .custom-control-input:checked:hover
  ~ .custom-control-label::before {
  background-color: darken($stripe-dark-primary, 5%);
  border-color: darken($stripe-dark-primary, 5%);
}

.custom-checkbox .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba($stripe-dark-primary, 0.25);
}

// Date picker
input[type="date"].form-control {
  background-color: darken($stripe-dark-bg, 5%);
  color: $stripe-dark-text;
  border-color: rgba($stripe-dark-text, 0.1);
}

input[type="date"].form-control:focus {
  border-color: $stripe-dark-primary;
  box-shadow: 0 0 0 0.2rem rgba($stripe-dark-primary, 0.25);
}
.nav-tabs {
  border-bottom-color: rgba($stripe-dark-text, 0.1);
}

.nav-tabs .nav-link {
  color: $stripe-dark-text;
  border-color: rgba($stripe-dark-text, 0.1);
}

.nav-tabs .nav-link:hover {
  color: $stripe-dark-primary;
  border-color: rgba($stripe-dark-text, 0.1) rgba($stripe-dark-text, 0.1)
    $stripe-dark-primary;
}

.nav-tabs .nav-link.active {
  color: $stripe-dark-primary;
  border-color: rgba($stripe-dark-text, 0.1) rgba($stripe-dark-text, 0.1)
    $stripe-dark-primary;
}
$alert-padding-y: 0.75rem;
$alert-padding-x: 1.25rem;
$alert-border-radius: 0.25rem;

.alert {
  position: relative;
  padding-left: 3rem; // Add extra padding to accommodate the icon
}

.alert::before {
  font-family: "Font Awesome 5 Free"; // Font Awesome 5 Free for solid icons
  font-weight: 900;
  position: absolute;
  left: 1.25rem;
  top: 50%;
  transform: translateY(-50%);
}

.alert-primary {
  background-color: darken($stripe-dark-primary, 10%);
  border-color: darken($stripe-dark-primary, 15%);
}

.alert-secondary {
  background-color: darken($stripe-dark-secondary, 10%);
  border-color: darken($stripe-dark-secondary, 15%);
}

.alert-info {
  background-color: darken(#48afea, 10%);
  border-color: darken(#48afea, 15%);
  color: $stripe-dark-text;
}
.alert-info::before {
  content: "\f05a"; // info-circle icon
}

.alert-warning {
  background-color: darken(#f9be5a, 10%);
  border-color: darken(#f9be5a, 15%);
  color: $stripe-dark-text;
}

.alert-danger {
  background-color: darken(#f4645f, 10%);
  border-color: darken(#f4645f, 15%);
  color: $stripe-dark-text;
}

.alert-success {
  background-color: darken(#23c277, 10%);
  border-color: darken(#23c277, 15%);
  color: $stripe-dark-text;
}

// modals
/* DarkModal.css */
.modal-content {
  background-color: #ffffff;
  border: 1px solid $primary;
  color: #0f0f0f;
  border-radius: 0;
}

.dark-modal .modal-header {
  color: #ffffff;
  .modal-title {
    .btn-close {
      color: #ffffff;
    }
    color: #ffffff;
  }
}
.modal-header {
  border-bottom: 1px solid $secondary;
}
.modal-footer {
  border-top: 1px solid $secondary;
}
// buttons
.btn {
  border-radius: 0.2rem;
}

.custom-dropdown {
  display: inline-block;
}

.form-select {
  border-radius: 5px;
  padding: 5px 10px;
  font-size: 16px;
  transition: background-color 0.2s, border-color 0.2s, color 0.2s;
}

.form-select:focus {
  outline: none;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  background-color: #f8f9fa;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

/* customQuillStyles.css */

.ql-toolbar.ql-snow {
  background-color: #e0e0e0;
  border: none;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.ql-editor {
  min-height: 100px;
}
