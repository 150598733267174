.section-header .card {
  background-color: transparent !important;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
  border-radius: 0px;
}

.section-header .card-header {
  background-color: transparent;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;
}

.section-header .card-header:hover {
  background-color: transparent;
}

.toggle-icon {
  transition: transform 0.3s;
}

.content-area {
  background-color: transparent;
}

.header-title {
  display: flex;
  align-items: center;
  padding: 0.4rem 1rem 0.4rem 0;
  position: relative;
}
.section-header {
  position: relative;
  width: 104%;
  left: -9px;
  top: -6px;
  right: 0;
  box-shadow: 1px 1px 4px #ffffff;
  padding: 0.4em;
  padding-right: 1.1rem;
  box-shadow: 1px 1px 1px #ddd;
  // mobile view
  @media (max-width: 576px) {
    .btn {
      padding: 0.2rem 0.4rem;
      font-size: 0.8rem;
    }
    h4 {
      font-size: 1rem;
    }

    width: 104%;
    left: -8px;
    top: -9px;
  }
}
.card-header:first-child {
  border-radius: 0 0 0 0;
}
