.cv-view {
  margin: 0 auto;
  top: -3rem;
  @media screen and (max-width: 576px) {
    aspect-ratio: 1/1.414;
    width: 26.25rem;
    height: 37.125rem;
    aspect-ratio: 1/1.414;
    max-width: 100%;
  }

  @media screen and (min-width: 576px) and (max-width: 768px) {
    aspect-ratio: 1/1.414;
    width: 26.25rem;
    height: 37.125rem;
    aspect-ratio: 1/1.414;
    max-width: 100%;
  }

  @media screen and (min-width: 768px) and (max-width: 992px) {
    aspect-ratio: 1/1.414;
    width: 26.25rem;
    height: 37.125rem;
    aspect-ratio: 1/1.414;
    max-width: 100%;
  }

  @media screen and (min-width: 992px) and (max-width: 1200px) {
    aspect-ratio: 1/1.414;
    width: 26.25rem;
    height: 37.125rem;
    aspect-ratio: 1/1.414;
    max-width: 100%;
  }

  @media screen and (min-width: 1200px) {
    aspect-ratio: 1/1.414;
    width: 38.25rem;
    height: 53.125rem;
    aspect-ratio: 1/1.414;
    max-width: 100%;
    position: relative;
    top: -1rem;
  }
}

.canvas-fade {
  transition: opacity 0.3s ease-in-out;
}
