html body {
  min-height: 100%;
}

input {
  background-color: rgba(0, 0, 0, 0) !important;
  color: #000000;
}

.footx {
  position: absolute;
  left: 50%;
  bottom: 20%;
  transform: translate(-50%, -50%);
  margin: 0 auto;
}

.start-modal-input {
  display: block;
  width: 100%;
  padding: 0.75rem 1.25rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.3;
  color: #16192c;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #000000;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 0.375rem;
  box-shadow: inset -5px 0px 20px 20px rgb(50 50 71 / 8%);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.hrx {
  display: block;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  margin-left: auto;
  margin-right: auto;
  border-style: inset;
  border-width: 1px;
}

.fgx {
  background-color: #fff;
  border: 1px solid rgb(1, 86, 120);
  color: #28434b;
  border-radius: 0;
}

.spx {
  width: 100%;
  height: 20px;
  border-bottom: 1px solid black;
  text-align: center;
}

.hx {
  font-size: 20px;
  background-color: #f3f5f6;
  padding: 0 10px;
  font-family: initial;
}

.btx {
  background-color: rgb(1, 86, 120);
  display: inline-block;
  font-weight: 500;
  line-height: 1.3;
  color: #fff;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.75rem 1.25rem;
  font-size: 1rem;
  border-radius: 0.375rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

/* do not group these rules */
*::-webkit-input-placeholder {
  color: #000000;
}

*:-moz-placeholder {
  /* FF 4-18 */
  color: #000000;
  opacity: 1;
}

*::-moz-placeholder {
  /* FF 19+ */
  color: #000000;
  opacity: 1;
}

*:-ms-input-placeholder {
  /* IE 10+ */
  color: #000000;
}

*::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #000000;
}

*::placeholder {
  /* modern browser */
  color: #000000;
}

.titlecls {
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 35px;
  color: #28434b;
  font-weight: 500;
  font-size: 30px;
  letter-spacing: 1px;
}

.descriptioncls {
  text-align: center;
  margin-bottom: 10px;
  color: #28434b;
  font-weight: 800;
  font-size: 15px;
  font-family: initial;
}

.lb {
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 30px;
  color: #28434b;
  font-weight: 140;
  font-size: 16px;
  letter-spacing: 1px;
}

.start-modal-wrap {
  max-width: 68%;
  margin: 20px auto 0 auto;
  transition: transform 300ms ease-in-out;
}

.start-modal-header {
  height: 45px;
  background: white;
  border-bottom: 1px solid #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
}

.start-modal-header span {
  display: block;
  height: 12px;
  width: 12px;
  margin: 5px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.2);
}

.start-modal-header span.start-is-active {
  background: rgba(0, 0, 0, 0.4);
}

.start-modal-bodies {
  position: relative;
  perspective: 1000px;
}

.start-modal-body {
  align-items: center;
  margin-bottom: 50px;
  position: fixed;
  text-align: center;
  background-color: #f4efef;
  top: 0;
  display: none;
  box-sizing: border-box;
  width: 100%;
  --border-width: 5px;
  justify-content: center;
  border-radius: var(--border-width);
}

/* .start-modal-body::after {
  position: absolute;
  content: "";
  top: calc(-1 * var(--border-width));
  left: calc(-1 * var(--border-width));
  z-index: -1;
  width: calc(100% + var(--border-width) * 2);
  height: calc(100% + var(--border-width) * 2);
  background: #28434b;
  background-size: 200% 200%;
  background-position: 50% 50%;
  border-radius: calc(2 * var(--border-width));
  animation: moveGradient 1s alternate infinite;
}

@keyframes moveGradient {
  50% {
    background-position: 100% 50%;
  }
} */

.start-modal-body input {
  margin-bottom: 20px;
}

.start-is-showing {
  display: block;
}

.start-animate-out {
  opacity: 0;
  transform: translate3d(0, -50px, 0);
  transition: 0.5s all ease-in-out;
  background: #f4efef;
  background-color: #f4efef;
  background-size: 0% 0%;
}
